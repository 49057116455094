header {
    width: 100%;
    height: 50px;
    background: linear-gradient(107deg, rgba(77,118,255,1) 0%, rgba(15,63,140,1) 100%);

    div.wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1200px;
        width: 100%;
        height: 100%;
        margin: auto;
        padding: 0 15px;

        div.brand {
            display: flex;
            align-items: center;

            img {
                height: 32px;
                margin: 0 15px 5px 0;
            }

            h1 {
                color: white;
                font-size: 24px;
                font-weight: 300;
                letter-spacing: 2px;
                margin: 0 0 2.5px 0;
                @media (max-width: 640px) {
                    font-size: 18px;
                }
            }
        }

        div.menu {
            display: flex;
            align-items: center;
            height: 100%;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 120px;
                height: 100%;
                color: white;
                text-decoration: none;
                font-size: 18px;
                font-weight: 300;
                letter-spacing: 2px;
                &:hover {
                    border-bottom: 5px solid white;
                }
            }
        }
    }
}
